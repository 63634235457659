import { differenceInSeconds } from 'date-fns'

export const convertHexToRGB = (hex) => {
    // check if it's a rgba
    if (hex.match('rgba')) {
        let triplet = hex.slice(5).split(',').slice(0, -1).join(',')
        return triplet
    }

    let c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')

        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')
    }
}

export function debounce(func, wait, immediate) {
    var timeout
    return function () {
        var context = this,
            args = arguments
        clearTimeout(timeout)
        timeout = setTimeout(function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }, wait)
        if (immediate && !timeout) func.apply(context, args)
    }
}

export function isMobile() {
    if (window) {
        return window.matchMedia(`(max-width: 767px)`).matches
    }
    return false
}

export function isMdScreen() {
    if (window) {
        return window.matchMedia(`(max-width: 1199px)`).matches
    }
    return false
}

function currentYPosition(elm) {
    if (!window && !elm) {
        return
    }
    if (elm) return elm.scrollTop
    // Firefox, Chrome, Opera, Safari
    if (window.pageYOffset) return window.pageYOffset
    // Internet Explorer 6 - standards mode
    if (document.documentElement && document.documentElement.scrollTop)
        return document.documentElement.scrollTop
    // Internet Explorer 6, 7 and 8
    if (document.body.scrollTop) return document.body.scrollTop
    return 0
}

function elmYPosition(elm) {
    var y = elm.offsetTop
    var node = elm
    while (node.offsetParent && node.offsetParent !== document.body) {
        node = node.offsetParent
        y += node.offsetTop
    }
    return y
}

export function scrollTo(scrollableElement, elmID) {
    var elm = document.getElementById(elmID)

    if (!elmID || !elm) {
        return
    }

    var startY = currentYPosition(scrollableElement)
    var stopY = elmYPosition(elm)

    var distance = stopY > startY ? stopY - startY : startY - stopY
    if (distance < 100) {
        scrollTo(0, stopY)
        return
    }
    var speed = Math.round(distance / 50)
    if (speed >= 20) speed = 20
    var step = Math.round(distance / 25)
    var leapY = stopY > startY ? startY + step : startY - step
    var timer = 0
    if (stopY > startY) {
        for (var i = startY; i < stopY; i += step) {
            setTimeout(
                (function (leapY) {
                    return () => {
                        scrollableElement.scrollTo(0, leapY)
                    }
                })(leapY),
                timer * speed
            )
            leapY += step
            if (leapY > stopY) leapY = stopY
            timer++
        }
        return
    }
    for (let i = startY; i > stopY; i -= step) {
        setTimeout(
            (function (leapY) {
                return () => {
                    scrollableElement.scrollTo(0, leapY)
                }
            })(leapY),
            timer * speed
        )
        leapY -= step
        if (leapY < stopY) leapY = stopY
        timer++
    }
    return false
}

export function getTimeDifference(date) {
    let difference = differenceInSeconds(new Date(), date)

    if (difference < 60) return `${Math.floor(difference)} sec`
    else if (difference < 3600) return `${Math.floor(difference / 60)} min`
    else if (difference < 86400) return `${Math.floor(difference / 3660)} h`
    else if (difference < 86400 * 30)
        return `${Math.floor(difference / 86400)} d`
    else if (difference < 86400 * 30 * 12)
        return `${Math.floor(difference / 86400 / 30)} mon`
    else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`
}

export function generateRandomId() {
    let tempId = Math.random().toString()
    let uid = tempId.substr(2, tempId.length - 1)
    return uid
}

export function getQueryParam(prop) {
    var params = {}
    var search = decodeURIComponent(
        window.location.href.slice(window.location.href.indexOf('?') + 1)
    )
    var definitions = search.split('&')
    definitions.forEach(function (val, key) {
        var parts = val.split('=', 2)
        params[parts[0]] = parts[1]
    })
    return prop && prop in params ? params[prop] : params
}

export function classList(classes) {
    return Object.entries(classes)
        .filter((entry) => entry[1])
        .map((entry) => entry[0])
        .join(' ')
}

export const flat = (array) => {
    var result = []
    array.forEach(function (a) {
        result.push(a)
        if (Array.isArray(a.children)) {
            result = result.concat(flat(a.children))
        }
    })
    return result
}
/*
@params: orders: Array
@returns: Array

This function takes an array of orders and returns an array of unique orders based on the orderId.
 */

const getUniqueOrders = (orders) => {
    const uniqueOrdersMap = new Map()

    orders?.forEach((order) => {
        if (!uniqueOrdersMap.has(order.orderId)) {
            uniqueOrdersMap.set(order.orderId, order)
        }
    })

    return Array.from(uniqueOrdersMap.values())
}

const apiCache = {
    cache: {},

    // Get data from cache
    get: function (key) {
        const cachedItem = this.cache[key]
        if (!cachedItem) return null

        // Check if cache is expired
        if (cachedItem.expiry && cachedItem.expiry < Date.now()) {
            delete this.cache[key]
            return null
        }

        return cachedItem.data
    },

    // Set data in cache with optional expiry time (in milliseconds)
    set: function (key, data, expiryTime = null) {
        this.cache[key] = {
            data,
            expiry: expiryTime ? Date.now() + expiryTime : null,
        }
    },

    // Clear specific cache item
    clear: function (key) {
        delete this.cache[key]
    },

    // Clear all cache
    clearAll: function () {
        this.cache = {}
    },
}

const invalidateCache = (
    endpoint,
    method = 'GET',
    body = undefined,
    server = 'x'
) => {
    const cacheKey = JSON.stringify({ endpoint, method, body, server })
    apiCache.clear(cacheKey)
}

// Utility function to clear all cache
const clearAllCache = () => {
    apiCache.clearAll()
}

const performanceMetrics = []

/**
 * Measures the execution time of a function and stores the metrics.
 * @param {Function} fn - The function to measure.
 * @param {string} fnName - The name of the function (for logging).
 * @param {...any} args - Arguments to pass to the function.
 * @returns {any} - The result of the function.
 */
function measurePerformance(fn, fnName, ...args) {
    const startTime = performance.now()
    const result = fn(...args)
    const endTime = performance.now()
    const duration = endTime - startTime

    // Store the metrics
    performanceMetrics.push({
        function: fnName,
        duration: duration,
        timestamp: new Date().toISOString(),
    })

    console.log(
        `[Performance] ${fnName} took ${duration.toFixed(2)} ms to execute.`
    )
    return result
}

/**
 * Clears all stored performance metrics.
 */
function clearPerformanceMetrics() {
    performanceMetrics.length = 0
    console.log('[Performance] All metrics cleared.')
}

/**
 * Returns all stored performance metrics.
 * @returns {Array} - Array of performance metrics.
 */
function getPerformanceMetrics() {
    return performanceMetrics
}

/**
 * Logs all stored performance metrics in a table format.
 */
function logPerformanceMetrics() {
    console.table(performanceMetrics)
}

/**
 * Exports performance metrics as a JSON file.
 * @param {string} fileName - The name of the file to export.
 */
function exportPerformanceMetrics(fileName = 'performanceMetrics.json') {
    const data = JSON.stringify(performanceMetrics, null, 2)
    const blob = new Blob([data], { type: 'application/json' })
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()

    URL.revokeObjectURL(url)
    console.log(`[Performance] Metrics exported to ${fileName}`)
}

// Example usage

// // Measure performance
// measurePerformance(() => expensiveCalculation(1000000), 'expensiveCalculation');
// measurePerformance(() => fetchData('https://jsonplaceholder.typicode.com/posts'), 'fetchData');

const getContentType = (fileName) => {
    const ext = fileName.split('.').pop().toLowerCase()
    switch (ext) {
        case 'pdf':
            return 'application/pdf'
        case 'mp4':
            return 'video/mp4'
        case 'mov':
            return 'video/quicktime'
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg'
        case 'png':
            return 'image/png'
        default:
            return 'application/octet-stream' // fallback
    }
}

function isImageUrl(url) {
    if (typeof url !== 'string') return false

    // List of common image extensions
    const imageExtensions = [
        'jpg',
        'jpeg',
        'png',
        'gif',
        'bmp',
        'webp',
        'svg',
        'tiff',
    ]

    // Extract file extension from URL
    const extension = url.split('.').pop().toLowerCase()

    return imageExtensions.includes(extension)
}

function isVideoUrl(url) {
    if (typeof url !== 'string') return false
    // List of common video extensions
    const videoExtensions = [
        'mp4',
        'avi',
        'mov',
        'wmv',
        'flv',
        'mkv',
        'webm',
        'mpeg',
    ]

    // Extract file extension from URL
    const extension = url.split('.').pop().toLowerCase()

    return videoExtensions.includes(extension)
}

function isLink(text) {
    if (typeof text !== 'string') return false

    // Regular expression to match URLs
    const urlPattern =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/i

    return urlPattern.test(text)
}

// -------------------- vendors ------------------------------------
/**
 * Filters a list of orders based on payment status and visibility.
 *
 * @param {Array} orders - The list of orders to filter.
 * @param {boolean} [isPayedByCustomerFilter=true] - Flag to filter orders based on whether they are paid by the customer.
 * @returns {Array} The filtered list of orders.
 */
const orderFilter = (orders, isPayedByCustomerFilter = true) => {
    const result = orders
        .filter((o) =>
            isPayedByCustomerFilter
                ? o?.payedByCustomer === undefined ||
                  o?.payedByCustomer === true
                : true
        )
        .filter((x) => !x?.newOrderHidden)
    return result
}
export {
    apiCache,
    clearAllCache,
    clearPerformanceMetrics,
    exportPerformanceMetrics,
    getContentType,
    getPerformanceMetrics,
    getUniqueOrders,
    invalidateCache,
    isImageUrl,
    isLink,
    isVideoUrl,
    logPerformanceMetrics,
    measurePerformance,
    orderFilter,
}

// get user from local storage

export const navigations = [
    {
        name: 'Products',
        path: '/dashboard/default',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Orders',
        path: '/orders',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Internet Orders',
        path: '/internet-orders',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Product Types',
        path: '/productTypes',
        icon: 'category',
    },
    {
        name: 'References',
        path: '/references',
        icon: 'category',
    },
    {
        name: 'Accountability',
        path: '/accountability',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Blogs',
        path: '/blogs',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Chat',
        path: '/chat',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Photos',
        path: '/photos',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Towns',
        path: '/towns',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Stadiums',
        path: '/stadiums',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Characters',
        path: '/characters',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Parts',
        path: '/parts',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Colors',
        path: '/colors',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Pages',
        path: '/pages',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Vendors',
        path: '/vendors',
        icon: 'category',
    },
    {
        name: 'Customers',
        path: '/customers',
        icon: 'category',
    },
    {
        name: 'Employees',
        path: '/employees',
        icon: 'category',
    },
    {
        name: 'Managers',
        path: '/managers',
        icon: 'category',
    },
    {
        name: 'Suppliers',
        path: '/suppliers',
        icon: 'category',
    },
    {
        name: 'Overrides',
        path: '/overrides',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
    {
        name: 'Categories',
        path: '/categories',
        icon: 'dashboard',
        role: ['SA', 'admin'],
    },
]

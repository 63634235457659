import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'

import { initializeApp } from 'firebase/app'

export const BASEURL = 'http://ec2-18-189-194-242.us-east-2.compute.amazonaws.com'
export const NODE_ENV= 'development'

export const FIREBASE_API_KEY ='AIzaSyDAf2etRazcoiR3wQcbTY9tDcg8bdnPaR8'
export const FIREBASE_AUTH_DOMAIN='cactus-chat-e9584.firebaseapp.com'
export const FIREBASE_PROJECT_ID='cactus-chat-e9584'
export const FIREBASE_STORAGE_BUCKET='cactus-chat-e9584.firebasestorage.app'
export const FIREBASE_MESSAGING_SENDER_ID=1091562898065
export const FIREBASE_APP_ID='1:1091562898065:web:45fb379d7e95a8a1543530'
export const FIREBASE_MEASUREMENT_ID='G-98LZ09E17K'

const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
    measurementId: FIREBASE_MEASUREMENT_ID,
}
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const analytics = getAnalytics(app)

export default app
export { analytics, db }
